<template>
  <div class="usage-card">
    <a-col class="overview" :span="6">
      <slot></slot>
    </a-col>
    <a-col :span="18" v-if="showChart">
      <div>
        <datetime-range
          style="width: 286px; position: absolute;
          right: 10px;
          top: -32px;
          z-index: 9;
          text-align: center;"
          :allow-clear="false"
          :default-value="datetimeRange"
          @ok="
            v => {
              datetimeRange = v
            }
          "
        ></datetime-range>
        <div
          style="
            position: absolute;
            right: 310px;
            top: -32px;
            z-index: 9;
          "
        >
          <a-checkbox v-model="compareYesterday">
            <question-icon
              title="环比"
              description="昨天同时段"
            ></question-icon>
          </a-checkbox>
          <a-checkbox v-model="compareLastWeek">
            <question-icon
              title="同比"
              description="上周同时段"
            ></question-icon>
          </a-checkbox>
        </div>
      </div>
      <multi-line-chart
        :chart-id="`${softwareType}-usage-chart`"
        :source-id="sourceId"
        :height="320"
        :history-func="historyFunc"
        :datetime-range="datetimeRange"
        :compare-last-week="compareLastWeek"
        :compare-yesterday="compareYesterday"
        :itemKey="itemKey"
        style="padding: 4px 4px 0 6px"
      ></multi-line-chart>
    </a-col>
    <a-col :span="18" v-if="!showChart">
      <slot name="contentCol"></slot>
    </a-col>
  </div>
</template>

<script>
import { getNetworkDeviceMonitorHistory } from '@/api/network-device'
import moment from 'moment'
import MultiLineChart from '@/components/chart/MultiLineChart'
import DatetimeRange from '@/components/DatetimeRange'
import QuestionIcon from '@/components/icon/QuestionIcon'

export default {
  name: 'UsageCard',
  props: {
    softwareType: {
      type: String,
      default: 'cpu'
    },
    height: {
      type: Number,
      default: 250
    },
    sourceId: {
      type: String
    },
    itemKey: {
      type: String
    },
    showChart: {
      type: Boolean,
      default: true
    }
  },
  components: {
    MultiLineChart,
    DatetimeRange,
    QuestionIcon
  },
  computed: {
    chartId () {
      return `${this.softwareType}-chart`
    }
  },
  data () {
    return {
      datetimeRange: [moment().subtract(1, 'hour'), moment()],
      compareYesterday: true,
      compareLastWeek: false,
      chartData: [],
      historyFunc: getNetworkDeviceMonitorHistory
    }
  },
  mounted () {
  },
  methods: {}
}
</script>

<style lang="less">
.software-page {
  .usage-card {
    height: 340px;
    .ant-descriptions-row > td {
      padding-bottom: 12px !important;
    }

    .overview {
      background: rgb(242, 249, 255);
      border-radius: 8px;
      height: 320px;
      padding-top: 16px;
    }
  }
}
</style>
